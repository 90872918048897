import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../utils/AuthContext';
import { Button, Modal } from 'react-bootstrap';

export default function DeleteCourseModal({ training }) {
  const { deleteTraining } = useContext(AuthContext);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = () => {
    handleClose();
    deleteTraining(training.id);
  };

  const handleClose = () => setShowDeleteConfirmation(false);
  const handleShow = () => setShowDeleteConfirmation(true);

  return (
    <>
      <Button className='ml-2' variant='danger' onClick={handleShow}>
        <img src='./APPIcons/trash-fill.svg'></img>
      </Button>

      <Modal show={showDeleteConfirmation} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete training course?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will delete all content and employee scores for this course. This action is irreversible.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
