import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Row, Col, Alert, Badge } from 'react-bootstrap';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';
import firebase from 'firebase/app';
import ExperienceSection from './ExperienceSection';
import { uploadFile, downloadFile, deleteFile } from '../../../utils/firebase';
import { AuthContext } from '../../../utils/AuthContext';
import formFields from './baseFields';

export default function Application({ position }) {
  const { db } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [show, setShow] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [data, setData] = useState({
    positionId: position.id,
    title: position.data.title,
    location: position.data.location,
    fname: '',
    lname: '',
    dob: '',
    phone: '',
    email: '',
    start: '',
    experience: [],
    questions: [],
    availability: false,
    status: 'draft',
  });

  const validateForm = (data) => {
    let isValid = true;
    if (!data.fname) isValid = false;
    if (!data.lname) isValid = false;
    if (!data.dob) isValid = false;
    if (!data.phone) isValid = false;
    if (!data.email) isValid = false;
    if (!data.start) isValid = false;
    return isValid;
  };

  const handleFieldChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const handleTextAreaChange = (idx, question, response) => {
    let questions = data.questions;
    questions[idx] = {
      question,
      response,
    };
    handleFieldChange('questions', questions);
  };

  const handleExperienceUpdate = (idx, state) => {
    let experience = data.experience;
    experience[idx] = state;
    handleFieldChange('experience', experience);
  };

  const addExperienceSection = () => {
    let experience = data.experience;
    experience.push(null);
    handleFieldChange('experience', experience);
  };

  const removeExperienceSection = () => {
    let experience = data.experience;
    experience.pop();
    handleFieldChange('experience', experience);
  };

  const saveDraft = async (position, data) => {
    setLoading(true);
    if (data?.id) {
      if (data?.file) await deleteFile(data);
      await db
        .collection('applications')
        .doc(data.id)
        .delete()
        .then(() => {
          delete data.file;
          document.getElementById('resume').value = null;
          setDocumentUrl(null);
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });
    }
    data.status = 'draft';
    localStorage.setItem(position.id, JSON.stringify(data));
    getCurrentApplication(position.id);
    setLoading(false);
  };

  const submitApplication = async (data) => {
    if (!validateForm(data)) {
      setValidationMsg('Please fill all required fields!');
      return;
    }
    setLoading(true);
    setValidationMsg('');
    data.status = 'submitted';
    data.updated = firebase.firestore.FieldValue.serverTimestamp();
    await db
      .collection('applications')
      .add({
        ...data,
      })
      .then(async (docRef) => {
        await uploadFile(selectedFile, data);
        data.id = docRef.id;
        localStorage.setItem(data.positionId, JSON.stringify(data));
        getCurrentApplication(position.id);
      })
      .catch((error) => {
        console.error('Error submitting application: ', error);
      });
    setLoading(false);
  };

  const getCurrentApplication = (positionId) => {
    if (localStorage.getItem(positionId) === null) return;
    let application = JSON.parse(localStorage.getItem(positionId));
    setData(application);
  };

  useEffect(() => {
    getCurrentApplication(position.id);
  }, []);

  useEffect(() => {
    data.status === 'submitted' ? setDisableForm(true) : setDisableForm(false);
    data?.file && downloadFile(data, setDocumentUrl);
  }, [data]);

  return (
    <>
      <Button
        disabled={!position.data.available}
        variant={position.data.available ? 'primary' : 'secondary'}
        className='button-standard'
        onClick={() => setShow(true)}
      >
        {position.data.available ? 'Apply now' : 'Check back later'}
      </Button>

      <Modal
        show={show}
        backdrop='static'
        size={'xl'}
        onHide={() => {
          setShow(false);
          setValidationMsg('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {position.data.title} - {position.data.location}{' '}
            <Badge variant={data.status === 'submitted' ? 'success' : 'warning'}>
              {data.status === 'submitted' ? 'Submitted' : 'Draft'}
            </Badge>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {disableForm ? (
            <Alert variant='success'>
              <p className='m-0'>
                This application has been submitted. If you would like to make changes, click "Undo submission" at the
                bottom of the form.
              </p>{' '}
              {documentUrl && (
                <>
                  <p className='m-0 d-inline'>Click the link to view your uploaded document</p>{' '}
                  <a href={documentUrl} target='_blank'>
                    {data.file}
                  </a>
                </>
              )}
            </Alert>
          ) : (
            <Alert variant='warning'>
              Please fill in all fields with as much detail as possible. Priority consideration will be given to
              applicants who best fit the availability requirements of this position. This position is frequently
              scheduled to work during {position.data.availability.toLowerCase()}
            </Alert>
          )}
          <Form>
            <Col>
              <Form.Group>
                <Form.Label htmlFor='resume' className='text-dark d-inline-flex w-50'>
                  Attach resume (optional)
                </Form.Label>
                <Form.Control
                  type='file'
                  id='resume'
                  name='resume'
                  disabled={disableForm}
                  accept='.pdf, .doc, .docx'
                  onChange={(e) => {
                    handleFieldChange('file', e.target.files[0].name);
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </Form.Group>
            </Col>
            <Form.Group>
              <Row className='pl-3 pr-3'>
                {formFields(data).map((field, idx) => (
                  <FormInput
                    key={idx}
                    label={field.label}
                    type={field.type}
                    id={field.id}
                    handleFieldChange={handleFieldChange}
                    disabled={disableForm}
                    defaultValue={field.defaultValue || null}
                  />
                ))}
              </Row>
            </Form.Group>
            <Form.Group>
              {data?.experience?.map((experience, idx) => (
                <Row className='border-top border-secondary p-3 bg-light' key={idx}>
                  <ExperienceSection
                    idx={idx}
                    handleExperienceUpdate={handleExperienceUpdate}
                    disabled={disableForm}
                    experience={experience || null}
                  />
                </Row>
              ))}
              <Row className='p-3 bg-light border-bottom border-secondary'>
                {data?.experience.length > 0 && !disableForm && (
                  <Button
                    variant='secondary'
                    className='ml-auto mr-auto'
                    onClick={removeExperienceSection}
                    disabled={disableForm}
                  >
                    - Work Experience
                  </Button>
                )}
                {data?.experience.length < 3 && !disableForm && (
                  <Button
                    variant='secondary'
                    className='ml-auto mr-auto'
                    onClick={addExperienceSection}
                    disabled={disableForm}
                  >
                    + Work Experience
                  </Button>
                )}
              </Row>
            </Form.Group>
            <Form.Group>
              <Row className='pl-3 pr-3'>
                {position?.data?.questions?.map((question, idx) => (
                  <FormTextArea
                    key={idx}
                    question={question}
                    idx={idx}
                    handleFieldChange={handleTextAreaChange}
                    disabled={disableForm}
                    defaultValue={data?.questions[idx]?.response}
                  />
                ))}
              </Row>
            </Form.Group>
            <Form.Group>
              <Row className='mt-3'>
                <Col className='text-right'>
                  <Button disabled={loading} variant='warning' onClick={() => saveDraft(position, data)}>
                    {disableForm ? 'Undo submission' : 'Save draft'}
                  </Button>
                  <Button
                    className='mx-3'
                    disabled={loading || disableForm}
                    variant='success'
                    onClick={() => submitApplication(data)}
                  >
                    Submit application {!data.file && '(no resume attached)'}
                  </Button>
                </Col>
              </Row>
              <Row>
                {validationMsg && (
                  <Alert variant='danger' className='ml-auto mr-auto mt-3'>
                    {validationMsg}
                  </Alert>
                )}
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
