import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import DeleteCourseModal from './DeleteCourseModal';
import { AuthContext } from '../../../../utils/AuthContext';

export default function CourseCreatorModal({ training, adminData }) {
  const { addTraining, updateTraining, getTrainings } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [questions, setQuestions] = useState([]);

  const handleClose = () => {
    setShow(false);
    setErrMessage('');
    !training && setQuestions([]);
  };
  const handleShow = () => setShow(true);

  const titleRef = useRef();
  const categoryRef = useRef();
  const typeRef = useRef();
  const urlRef = useRef();

  const questionValidation = () => {
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].question) {
        setErrMessage('Empty questions are not allowed');
        return false;
      }
      for (let x = 0; x < questions[i].choices.length; x++) {
        if (!questions[i].choices[x]) {
          setErrMessage('Empty choices are not allowed');
          return false;
        }
      }
      if (!questions[i].answer) {
        setErrMessage('Please select an answer');
        return false;
      }
    }
  };

  const isValidated = () => {
    setErrMessage('');

    if (!titleRef.current.value) {
      setErrMessage('Please enter a title');
      return false;
    }

    if (!categoryRef.current.value || categoryRef.current.value === '---') {
      setErrMessage('Please select a category');
      return false;
    }

    if (!typeRef.current.value || typeRef.current.value === '---') {
      setErrMessage('Please select a content type');
      return false;
    }

    const regex = new RegExp(
      '((http|https)://)(www.)?' +
        '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
        '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
    );
    if (!urlRef.current.value || regex.test(urlRef.current.value) === false) {
      setErrMessage('Url is missing or in an incorrect format (ex: https://www.youtube.com/embed/xxxxxxxxxxx');
      return false;
    }

    if (questionValidation() === false) {
      return false;
    }

    return true;
  };

  const submit = (e) => {
    e.preventDefault();
    if (!isValidated()) return;
    if (!training) {
      addTraining(
        titleRef.current.value,
        categoryRef.current.value,
        typeRef.current.value,
        urlRef.current.value,
        questions
      );
    }
    if (training) {
      updateTraining(
        training.id,
        titleRef.current.value,
        categoryRef.current.value,
        typeRef.current.value,
        urlRef.current.value,
        questions
      );
    }

    getTrainings();
    handleClose();
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        answer: '',
        choices: [''],
        question: '',
      },
    ]);
  };

  const removeQuestion = () => {
    let tempQuestions = [...questions];
    tempQuestions.pop();
    setQuestions(tempQuestions);
  };

  const setQuestion = (e, idx) => {
    let tempQuestions = [...questions];
    tempQuestions[idx].question = e.target.value;
    setQuestions(tempQuestions);
  };

  const addChoice = (idx) => {
    let tempQuestions = [...questions];
    tempQuestions[idx].choices.push('');
    setQuestions(tempQuestions);
  };

  const removeChoice = (idx) => {
    let tempQuestions = [...questions];
    tempQuestions[idx].choices.pop();
    setQuestions(tempQuestions);
  };

  const setChoice = (e, idx, idy) => {
    let tempQuestions = [...questions];
    tempQuestions[idx].choices[idy] = e.target.value;
    setQuestions(tempQuestions);
  };

  const setAnswer = (e, idx) => {
    let tempQuestions = [...questions];
    tempQuestions[idx].answer = e.target.value;
    setQuestions(tempQuestions);
  };

  useEffect(() => {
    training && setQuestions(training?.data?.questions);
  }, []);

  return (
    <Col xs={12} className='pt-3 pb-3 border'>
      {!training ? (
        <Row>
          <Col className='ml-auto mr-auto' sm={8} md={6} lg={5}>
            <Button className='w-100 button-standard' variant='primary' onClick={handleShow}>
              Create new course
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className='d-flex'>
          <Col xs={12} className='pb-3'>
            {training.data.title}
          </Col>
          <Col className='text-right'>
            <Button className='button-standard' variant='primary' onClick={handleShow}>
              <img src='./APPIcons/edit.svg'></img>
            </Button>
            <DeleteCourseModal training={training} />
          </Col>
        </Row>
      )}

      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{!training ? 'Create a course' : `Edit Course "${training.data.title}"`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submit}>
            <Form.Group className='mb-3' controlId='courseTitle'>
              <Form.Label className='text-dark'>Course title</Form.Label>
              <Form.Control
                defaultValue={training && training?.data?.title}
                type='text'
                placeholder='Enter course title'
                ref={titleRef}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='courseCategory'>
                  <Form.Label className='text-dark'>Select category</Form.Label>
                  <select className='p-1 w-100' defaultValue={training?.data.category} name='category' id='category' ref={categoryRef}>
                    <option value=''>---</option>
                    {adminData?.trainingCategories.map((category, idz) => (
                      <option key={idz} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-3' controlId='courseContentType'>
                  <Form.Label className='text-dark'>Select content type</Form.Label>
                  <select className='p-1 w-100' defaultValue={training?.data.contentType} name='contentType' id='contentType' ref={typeRef}>
                    <option value=''>---</option>
                    {adminData?.contentTypes.map((type, idz2) => (
                      <option key={idz2} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='mb-4' controlId='url'>
              <Form.Label className='text-dark'>Content url</Form.Label>
              <Form.Control
                defaultValue={training && training?.data?.contentUrl}
                type='text'
                placeholder='Enter youtube embed url'
                ref={urlRef}
              />
            </Form.Group>
          </Form>

          {questions.map((question, idx) => (
            <div key={idx} className='border-top border-dark'>
              <Form.Group className='mb-3' controlId={`question${idx}`}>
                <Form.Label className='text-dark mt-4'>
                  <strong>Question {idx + 1}</strong>
                </Form.Label>
                <Form.Control
                  type='text'
                  defaultValue={question.question}
                  placeholder='Enter new quiz question'
                  onChange={(e) => setQuestion(e, idx)}
                />
              </Form.Group>

              {question.choices.map((choice, idy) => (
                <Form.Group className='mb-2' key={idy} controlId={`choice${idy}`}>
                  <Form.Label className='text-dark mt-0'>Choice {idy + 1}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={choice}
                    placeholder='Enter a choice'
                    onChange={(e) => setChoice(e, idx, idy)}
                  />
                </Form.Group>
              ))}

              <Button variant='success' onClick={() => addChoice(idx)}>
                Add choice
              </Button>
              {question.choices.length > 0 && (
                <Button variant='danger' className='ml-2' onClick={() => removeChoice(idx)}>
                  Remove choice
                </Button>
              )}

              <Form.Group className='mb-3 mt-4' controlId='answer'>
                <Form.Label className='text-dark'>Select correct answer</Form.Label>
                <select className='p-1 w-100' defaultValue={question?.answer} name='contentType' id='contentType' onChange={(e) => setAnswer(e, idx)}>
                  <option value=''>---</option>
                  {question.choices.map((choice, idz3) => (
                    <option value={choice} key={idz3}>
                      {choice}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </div>
          ))}

          <Button variant='success' onClick={addQuestion} type='submit'>
            Add question
          </Button>
          {questions.length > 0 && (
            <Button variant='danger' className='ml-2' onClick={removeQuestion}>
              Remove question
            </Button>
          )}
        </Modal.Body>
        <Modal.Footer>
          {errMessage && <Alert variant='warning'>{errMessage}</Alert>}

          <Button variant='primary' className='ml-auto button-standard' onClick={submit} type='submit'>
            Save course
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
