import React, { useContext } from 'react';
import logo from '../pages/assets/logo.png';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import { app } from '../utils/firebase';

export default function ImprovedNav() {
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const signOut = () => {
    app
      .auth()
      .signOut()
      .then(() => {
        navigate('/');
      });
  };

  return (
    <Navbar expand='sm'>
      <Navbar.Brand>
        <a href='https://www.atlaspet.net'>
          <img src={logo} alt='logo' className='logo' />
        </a>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls='main-navbar-nav' />
      <Navbar.Collapse id='main-navbar-nav' className='justify-content-end px-3'>
        <Nav className='me-auto'>
          {location.pathname.includes('Careers') ? null : (
            <Nav.Link onClick={() => navigate('/Careers')}>
              Careers
            </Nav.Link>
          )}

          {currentUser ? (
            <>
              <Nav.Link onClick={() => navigate('/Employee-Home')}>
                Employee Home
              </Nav.Link>
              <Nav.Link onClick={signOut}>
                Sign Out
              </Nav.Link>
            </>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
