import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase';
import { Container, Row, Col, Card, Button, Badge, Alert } from 'react-bootstrap';
import { ClientContext } from '../../../utils/ClientContext';
import { ActionSection, AppStatusModal } from './helpers/AppStatus';
import { AuthContext } from '../../../utils/AuthContext';
import { downloadFile } from '../../../utils/firebase';

export default function ApplicationViewer() {
  const db = firebase.firestore();

  const [applications, setApplications] = useState([]);
  const [fullApp, setFullApp] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const { convertDate } = useContext(ClientContext);
  const { employeeData } = useContext(AuthContext);

  const fullApplication = async (id) => {
    let appArr = applications.filter((app) => app.id === id);
    let fullApplication = appArr[0];
    if (fullApplication?.data?.file) {
      await downloadFile(fullApplication.data, setDownloadUrl);
    }
    setFullApp(fullApplication);

    var view = document.getElementById('details');
    view.scrollIntoView();
  };

  const pullApplications = () => {
    return db
      .collection('applications')
      .where('status', 'in', ['submitted', 'reviewed', 'scheduled'])
      .orderBy('updated', 'desc')
      .onSnapshot((docs) => {
        let applications = [];
        docs.forEach((doc) => {
          let data = doc.data();
          if (!data.status) {
            return;
          }
          if (data.location === employeeData?.location || employeeData.location === 'All') {
            applications.push({ data: data, id: doc.id });
          }
        });
        setApplications(applications);
      });
  };

  const assignStatus = (status) => {
    switch (status) {
      case 'reviewed':
        return 'warning';
        break;

      case 'scheduled':
        return 'success';
        break;

      default:
        return '';
        break;
    }
  };

  useEffect(() => {
    let unsubscribe = pullApplications();
    return () => unsubscribe();
  }, [employeeData]);

  return (
    <Container>
      <div className='row position-relative'>
        <section className='print-hide app-list mt-0 p-3 col-md-5'>
          <h1 className='size-24 text-center app-header-border pb-3'>Submitted Applications</h1>
          {applications.map((app, idx) => (
            <Card key={idx} className='border w-100 text-left'>
              <Card.Header className='size-20 border-0'>
                <Row>
                  <Col>{app?.data?.position ? app.data.position : `${app.data.title} - ${app.data.location}`}</Col>
                  <Col xs={4} className='text-right'>
                    <Badge variant={assignStatus(app.data.status)}>
                      {app.data.status !== 'submitted' && app.data.status}
                    </Badge>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {app.data.fname} {app.data.lname}
                  </Col>
                  <Col xs={4} className='text-right'>
                    <Button onClick={() => fullApplication(app.id)} id={app.id} key={idx}>
                      View App
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
        </section>
        <section className='col-md-7 p-3 app-list position-relative print-width'>
          <h1 className='size-24 text-center app-header-border pb-3 print-hide' id='details'>
            Details
          </h1>
          <div className='position-relative'>
            {fullApp && (
              <Container>
                <div className='d-flex justify-content-between'>
                  <AppStatusModal appId={fullApp.id} setFullApp={setFullApp} fullApp={fullApp} />
                  <div>
                    <Button
                      className='print-hide'
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <img src='/APPIcons/printer-fill.svg' alt='Print Application' />
                    </Button>
                    <Button
                      className='print-hide ml-2'
                      onClick={() => {
                        setFullApp(null);
                      }}
                    >
                      <img src='/APPIcons/x-lg.svg' alt='Close' />
                    </Button>
                  </div>
                </div>
                <h2 className='size-20 text-center'>
                  {fullApp?.data?.position ? fullApp.data.position : `${fullApp.data.title} - ${fullApp.data.location}`}
                </h2>

                <Row className='border-bottom'>
                  <Col className='p-3'>
                    <ActionSection actions={fullApp?.data?.actions} />
                  </Col>
                </Row>
                {fullApp?.data?.file && (
                  <Row>
                    <Col><Alert variant='info' className='text-center mb-0 mt-3 print-hide'>
                      <p className='m-0 d-inline'>View attached resume: </p>
                      <a href={downloadUrl} target='_blank'>{fullApp.data.file}</a></Alert>
                    </Col>
                  </Row>
                )}

                <Row className='pt-3 pb-3 border-bottom'>
                  <Col xs={6}>
                    <p className='app-info-display'>
                      <strong>Applicant Name:</strong> {fullApp.data.fname} {fullApp.data.lname}
                    </p>
                    <p className='app-info-display'>
                      <strong>Date of Birth:</strong> {convertDate(fullApp.data.dob)}
                    </p>
                    <p className='app-info-display'>
                      <strong>Avalable to start:</strong> {convertDate(fullApp.data.start)}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p className='app-info-display'>
                      <strong>Email:</strong> {fullApp.data.email}
                    </p>
                    <p className='app-info-display'>
                      <strong>Phone:</strong> {fullApp.data.phone}
                    </p>
                    <p className='app-info-display'>
                      <strong>Submitted:</strong> {convertDate(fullApp.data.updated.seconds * 1000)}
                    </p>
                  </Col>
                </Row>

                <Row className='pt-3 pb-3 border-bottom'>
                  <Col xs={12}>
                    <p className='text-center'>
                      <strong>Work History</strong>
                    </p>
                  </Col>
                  {fullApp?.data.experience.map((data, idx) => (
                    <Col xs={12} key={idx}>
                      <div>
                        <p className='app-info-display'>
                          <strong>Title:</strong> {data.title}
                        </p>
                        <p className='app-info-display'>
                          <strong>Dates:</strong> {convertDate(data.start)} - {data.end ? convertDate(data.end) : 'current'}
                        </p>
                        <p className='app-info-display'>
                          <strong>Company:</strong> {data.company}
                        </p>
                        <pre className='app-info-display border-0'>{data.description}</pre>
                        <br />
                      </div>
                    </Col>
                  ))}
                  {fullApp.data.experience.length === 0 && (
                    <Col className='text-center'>
                      <p>None recorded</p>
                    </Col>
                  )}
                </Row>

                <Row className='pt-3 pb-3 border-bottom'>
                  <Col xs={12}>
                    <p className='text-center'>
                      <strong>Application Questions</strong>
                    </p>
                  </Col>
                  {fullApp?.data.questions.map((data, idx) => (
                    <Col xs={12} key={idx}>
                      <div key={idx}>
                        <p className='app-info-display'>
                          <strong>{data.question}</strong>
                        </p>
                        <pre className='border-0'>{data.response}</pre>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
            {!fullApp && (
              <Row>
                {applications.length > 0 ? (
                  <Col md={7} className='ml-auto mr-auto text-center p-3'>
                    <p>Select an application to view and print details.</p>
                  </Col>
                ) : (
                  <Col md={7} className='ml-auto mr-auto text-center p-3'>
                    <p>
                      <strong>No new applications!</strong>
                    </p>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </section>
      </div>
    </Container>
  );
}
