import React from 'react';
import { Form, FormLabel } from 'react-bootstrap';

export default function ReportField({
  reportTotals,
  register,
  id,
  calculated,
  calculatedValue,
  label,
  type,
  className,
  placeholder,
  setRegisterTotals,
  extraLabels,
  disableOverride
}) {
  return (
    <>
      <Form.Group className='mb-3 d-flex' controlId={`${id}${register}`}>
        {label && <FormLabel className='w-100'>{label}</FormLabel>}
        <Form.Control
          disabled={calculated || disableOverride}
          type={type === 'textarea' ? 'text' : type}
          className={className}
          placeholder={placeholder}
          value={calculated ? calculatedValue(reportTotals, register) : reportTotals[register][id] || ''}
          onChange={(e) => {
            setRegisterTotals(id, type === 'number' ? parseFloat(e.target.value) : e.target.value);
          }}
        />
      </Form.Group>
      {extraLabels && (
        <div className='d-flex'>
          <Form.Label className='w-100 text-left mt-0 mb-3'>
            <em>Keep $100 in register:</em>
          </Form.Label>
          <Form.Label className='w-100 text-right mt-0 mb-3'>
            <span className='text-danger'>- $100.00</span>
          </Form.Label>
        </div>
      )}
    </>
  );
}
