import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ImprovedNav from './components/ImprovedNav';
import ClientContextProvider from './utils/ClientContext';
import { AuthProvider } from './utils/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { Routing } from './components/routing/Routing';

function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <Router>
          <ClientContextProvider>
              <ImprovedNav />
              <Routing />
          </ClientContextProvider>
        </Router>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
