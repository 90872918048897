import React, { useState, useEffect } from 'react';
import FormInput from './FormInput';

export default function ExperienceSection({ idx, handleExperienceUpdate, experience, disabled }) {
  const [workHistory, setWorkHistory] = useState({
    company: experience?.company || '',
    title: experience?.title || '',
    start: experience?.start || '',
    end: experience?.end || '',
    leaveReason: experience?.leaveReason || '',
    description: experience?.description || '',
  });

  const handleFieldChange = (field, value) => {
    setWorkHistory({
      ...workHistory,
      [field]: value,
    });
  };

  useEffect(() => {
    handleExperienceUpdate(idx, workHistory);
  }, [workHistory]);

  return (
    <>
      <FormInput
        label='Company name'
        type='text'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.company}
        disabled={disabled}
        id='company'
      />
      <FormInput
        label='Job title'
        type='text'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.title}
        disabled={disabled}
        id='title'
      />
      <FormInput
        label='Start date'
        type='date'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.start}
        disabled={disabled}
        id='start'
      />
      <FormInput
        label='End date'
        type='date'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.end}
        disabled={disabled}
        id='end'
      />
      <FormInput
        size='12'
        label='Reason for leaving'
        type='text'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.leaveReason}
        disabled={disabled}
        id='leaveReason'
      />
      <FormInput
        size='12'
        label='Describe your role'
        type='text'
        handleFieldChange={handleFieldChange}
        defaultValue={experience?.description}
        disabled={disabled}
        id='description'
      />
    </>
  );
}
