import React from 'react';
import { Card, Col } from 'react-bootstrap';
import Application from './Application';

export default function PositionCard({ position }) {
  return (
    <Card>
      <Card.Header as='h5'>
        {position.data.title} - {position.data.location}
      </Card.Header>
      <Card.Body>
        <Card.Text>{position.data.classification}</Card.Text>
        <Card.Text>{position.data.wage}</Card.Text>
        <Card.Text><strong>Regular availabiltiy required - {position.data.availability}</strong></Card.Text>
        <Card.Text>{position.data.description}</Card.Text>
        <Col className='text-right'>
          <Application position={position} />
        </Col>
      </Card.Body>
    </Card>
  );
}
