// Importing firebase SDK
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const app = firebase.initializeApp(config);
const storageRef = firebase.storage().ref();

const generatePathString = (data) =>
  `applicationDocs/${data.location}/${data.positionId}/${data.lname}_${data.fname}_${data.file}`;

const uploadFile = async (file, data, setMsg = false) => {
  if (file && data.file) {
    const applicationDocRef = storageRef.child(generatePathString(data));
    await applicationDocRef
      .put(file)
      .then((snapshot) => {
        setMsg && setMsg(`File ${data.positionId}_${data.file} uploaded`);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const deleteFile = async (data, setMsg = false) => {
  const applicationDocRef = storageRef.child(generatePathString(data));
  await applicationDocRef
    .delete()
    .then(() => {
      setMsg && setMsg(`File ${data.positionId}_${data.file} deleted`);
    })
    .catch((error) => {
      console.error(error);
    });
};

const downloadFile = async (data, setLink, setMsg = false) => {
  const applicationDocRef = storageRef.child(generatePathString(data));
  await applicationDocRef
    .getDownloadURL()
    .then((url) => {
      setLink(url);
      setMsg && setMsg(`File ${data.positionId}_${data.file} downloaded`);
    })
    .catch((error) => {
      console.error(error);
    });
};

export { app, uploadFile, downloadFile, deleteFile };
