import React, { useContext } from 'react';
import { AuthContext } from '../../utils/AuthContext';
import { Container } from 'react-bootstrap';
import FullScreenLoader from '../../components/FullScreenLoader';
import Training from '../../components/employee/training/Training';

export default function EmployeeHome() {
  const { loading } = useContext(AuthContext);

  return (
    <Container fluid className='p-0 full-height background-light'>
      {loading && <FullScreenLoader />}
      {!loading && <Training />}
    </Container>
  );
}
