import React, { useState } from 'react';
import { Row, Col, Alert, Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { app } from '../../utils/firebase';
import firebase from 'firebase';

export default function EmployeeLogin() {
  const [alertMsg, setAlertMsg] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    app
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        app
          .auth()
          .signInWithEmailAndPassword(formData.email, formData.password)
          .then((userCredential) => {
            userCredential && navigate('/Employee-Home');
          })
          .catch((error) => {
            setAlertMsg(error.message);
          });
      })
      .catch((error) => {
        setAlertMsg(error.message);
      });
  };

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Row>
      <Helmet>
        <title>Employee Login | Atlas Pet</title>
      </Helmet>
      <Col sm={8} lg={6} className='ml-auto mr-auto'>
        <Form className='border p-5 rounded shadow'>
          <h1 className='display-4'>Login</h1>
          <Form.Group className='mb-3' controlId='formEmail'>
            <Form.Label className='text-dark'>Email</Form.Label>
            <Form.Control type='email' onChange={(e) => handleFormChange('email', e.target.value)} />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formPassword'>
            <Form.Label className='text-dark'>Password</Form.Label>
            <Form.Control type='password' onChange={(e) => handleFormChange('password', e.target.value)} />
          </Form.Group>

          <Row>
            <Col xs={8}>
              {alertMsg && (
                <Alert className=' mt-4' variant='danger'>
                  {alertMsg}{' '}
                  <Alert.Link onClick={() => navigate('/Reset-Password')}>
                    <u>Reset your password.</u>
                  </Alert.Link>
                </Alert>
              )}
            </Col>
            <Col className='text-right'>
              <Button className='mt-3' variant='primary' type='button' onClick={(e) => handleLogin(e)}>
                Login
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
