import React, { useEffect, useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../../utils/AuthContext';
import { ClientContext } from '../../../utils/ClientContext';
import PositionGroup from './helpers/PositionGroup';

export default function CareerManagement() {
  const { db, employeeData } = useContext(AuthContext);
  const { getPositionData } = useContext(ClientContext);

  const [positions, setPositions] = useState([]);

  const checkLocation = (location) => {
    if (location === 'All') return ['Blaine', 'Stillwater'];
    if (location !== 'All') return [location];
  };

  const updateAvailability = (positionId, available) => {
    db.collection('employmentPositions').doc(positionId).set(
      {
        available,
      },
      { merge: true }
    );
  };

  useEffect(() => {
    if (employeeData?.location) {
      let unsubscribe = getPositionData(checkLocation(employeeData.location), setPositions);
      return () => unsubscribe();
    }
  }, [employeeData]);

  return (
    <Container>
      <Row>
        <Col>
          <PositionGroup
            location='Blaine'
            positions={positions.filter((position) => position.data.location === 'Blaine')}
            onChange={updateAvailability}
          />
          <PositionGroup
            location='Stillwater'
            positions={positions.filter((position) => position.data.location === 'Stillwater')}
            onChange={updateAvailability}
          />
        </Col>
      </Row>
    </Container>
  );
}
