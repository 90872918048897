import React, { useRef } from 'react';
import { Form, Col } from 'react-bootstrap';

export default function FormInput({ size = '6', label, type, id, handleFieldChange, defaultValue, disabled }) {
  const ref = useRef();

  return (
    <Col md={size}>
      <Form.Label className='text-dark d-inline-flex w-50'>{label}</Form.Label>
      <Form.Control
        defaultValue={defaultValue}
        onChange={() => {
          handleFieldChange(id, ref.current.value);
        }}
        type={type}
        id={id}
        name={id}
        ref={ref}
        disabled={disabled}
      />
    </Col>
  );
}
