import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CourseCreatorModal from './helpers/CourseCreatorModal';
import { AuthContext } from '../../../utils/AuthContext';

export default function CourseCreator() {
  const { trainings, adminData, getAdminData } = useContext(AuthContext);

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <CourseCreatorModal adminData={adminData} />
          </Row>

          {trainings.map((training, idx) => (
            <Row key={idx}>
              <CourseCreatorModal training={training} adminData={adminData} />
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
}
