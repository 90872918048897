import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { AuthContext } from '../../../utils/AuthContext';
import SaleReportModal from './helpers/SaleReportModal';
import { calculateGrandTotal, calculateTotalDeposit } from './helpers/reportFields/reportFields';
import DeleteSaleReport from './helpers/DeleteSaleReport';

export default function SaleTotals() {
  const { employeeData, db } = useContext(AuthContext);

  const locations = [
    {
      city: 'blaine',
      cityState: 'Blaine, MN',
    },
    {
      city: 'stillwater',
      cityState: 'Stillwater, MN',
    },
  ];

  const [key, setKey] = useState(null);
  const [disabledTabs, setDisabledTabs] = useState([locations[0].city, locations[1].city]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (employeeData?.location === 'All') {
      setDisabledTabs([]);
      setKey(locations[0].city);
    } else {
      let tabs = [locations[0].city, locations[1].city];
      setDisabledTabs(tabs.filter((tab) => tab !== employeeData?.location?.toLowerCase()));
      setKey(employeeData?.location?.toLowerCase());
    }
  }, [employeeData]);

  useEffect(() => {
    db.collection('salesData')
      .orderBy('reportDate', 'desc')
      .orderBy('updated', 'desc')
      .onSnapshot((querySnapshot) => {
        let tempReports = [];
        querySnapshot.forEach((doc) => {
          tempReports.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setReports(tempReports);
      });
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Tabs id='location-tabs' className='mb-3' activeKey={key} onSelect={(k) => setKey(k)}>
            {locations.map((location) => (
              <Tab
                key={location.city}
                eventKey={location.city}
                title={location.cityState}
                disabled={disabledTabs.includes(location.city)}
              >
                <Row>
                  <Col>
                    Click "Add record" to record a new sales total entry. Once submitted a sales record can no longer be
                    edited. Only submit one record for each date.
                  </Col>
                  <Col xs={3} sm={2} className='pr-0'>
                    <SaleReportModal location={location} />
                  </Col>
                </Row>
                <Row className='py-2 mt-3 border-top'>
                  <Col className='pr-0'>
                    <strong>Report date</strong>
                  </Col>
                  <Col xs={3} sm={2} className='pr-0'>
                    <strong>Submitted by</strong>
                  </Col>
                  <Col xs={3} sm={2} className='pr-0 text-right'>
                    <strong>Grand total</strong>
                  </Col>
                  <Col xs={3} sm={2} className='pr-0 text-right'>
                    <strong>Deposit</strong>
                  </Col>
                  <Col xs={6} sm={1} className='pr-0'></Col>
                  <Col xs={6} sm={2} className='p-0'></Col>
                </Row>
                {reports
                  .filter((report) => report.data.location.city === location.city)
                  .map((filteredReport) => (
                    <Row className='py-2 border-top'>
                      <Col className='pr-0'>{filteredReport.data.reportDate}</Col>
                      <Col xs={3} sm={2} className='pr-0'>
                        {filteredReport.data.submittedBy}
                      </Col>
                      <Col xs={3} sm={2} className='pr-0 text-right'>
                        {calculateGrandTotal(filteredReport.data)}
                      </Col>
                      <Col xs={3} sm={2} className='pr-0 text-right'>
                        {calculateTotalDeposit(filteredReport.data)}
                      </Col>
                      <Col xs={6} sm={1} className='pr-0 text-center'>
                        <DeleteSaleReport submitted={filteredReport.data.submitted} id={filteredReport.id} />
                      </Col>
                      <Col xs={6} sm={1} className='p-0 text-center'>
                        <SaleReportModal
                          location={location}
                          savedReport={filteredReport.data}
                          id={filteredReport.id}
                          submitted={filteredReport.data.submitted}
                        />
                      </Col>
                    </Row>
                  ))}
              </Tab>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
