import React, { useState, useContext } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { AuthContext } from '../../../../utils/AuthContext';
import QuizToggleRadio from './QuizToggleRadio';

export default function TrainingModal({ training }) {
  const { setCourseCompletion, getEmployee } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [answerArray, setAnswerArray] = useState([]);
  const { data, id } = training;

  const handleClose = () => {
    setShow(false);
    setAnswerArray([]);
  };

  const setQuestionResponse = (question, answer) => {
    let tempState = [...answerArray];
    if (data.questions[question].answer === answer) {
      tempState[question] = true;
      setAnswerArray(tempState);
    } else {
      tempState[question] = false;
      setAnswerArray(tempState);
    }
  };

  const submitQuiz = () => {
    let correctAnswerTotal = answerArray.filter((answer) => answer === true);
    let scorePercentage = Math.floor((correctAnswerTotal.length / data.questions.length) * 100);
    setCourseCompletion(id, scorePercentage);
    handleClose();
    getEmployee();
  };

  return (
    <>
      <Button variant='primary' className='button-standard' onClick={() => setShow(true)}>
        Begin Course
      </Button>

      <Modal scrollable={true} show={show} className='fullscreen-modal' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='pl-5 pr-5'>
            <Col className='video-wrapper'>
              <iframe
                width='100%'
                src={data.contentUrl}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
          <Row className='pl-5 pr-5'>
            <Form className='w-100'>
              {data.questions.map((question, idx) => (
                <Form.Group key={idx} className='mb-3 border-bottom' controlId='formBasicEmail'>
                  <Form.Text className='pb-0 pt-3 h3'>{question.question}</Form.Text>
                  <QuizToggleRadio id={idx} choices={question.choices} setQuestionResponse={setQuestionResponse} />
                </Form.Group>
              ))}

              <Button variant='primary' className='button-standard' onClick={submitQuiz} type='button'>
                Submit
              </Button>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
