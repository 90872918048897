import React, { useRef, useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { AuthContext } from '../../../../utils/AuthContext';

export default function ReportDetails({ reportTotals, setReportTotals, disabled }) {
  const reportDate = useRef()
  const { employeeData } = useContext(AuthContext);


  return (
    <Col>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='reportDate'>
            <Form.Label>Report date</Form.Label>
            <Form.Control disabled={disabled} type='date' ref={reportDate} onChange={() => setReportTotals({...reportTotals, reportDate: reportDate.current.value})} defaultValue={reportTotals.reportDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3' controlId='submittedBy'>
            <Form.Label>Submitted by</Form.Label>
            <Form.Control disabled type='text' value={`${employeeData.fName} ${employeeData.lName}`} />
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
}
