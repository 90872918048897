import React from 'react'
import { Spinner, Row, Col } from 'react-bootstrap'

export default function FullScreenLoader() {
    return (
        <Row className="fullscreen-wrapper">
            <Col xs={8} className="ml-auto mr-auto text-center">
                <Spinner animation="border" className="m-4 fullscreen-loader"/>
            </Col>
        </Row>
    )
}
