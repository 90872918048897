import React, { createContext } from 'react';
import { app } from '../utils/firebase';
import firebase from 'firebase/app';

export const ClientContext = createContext();

const ClientContextProvider = (props) => {
  var db = firebase.firestore(app);

  const getPositionData = (locations, setState) => {
    return db
      .collection('employmentPositions')
      .where('location', 'in', locations)
      .orderBy('available', 'desc')
      .orderBy('location')
      .onSnapshot((docs) => {
        let positions = [];
        docs.forEach((doc) => {
          positions.push({ id: doc.id, data: doc.data() });
        });
        setState(positions);
      });
  };

  const signOut = () => {
    app.auth().signOut();
  };

  const convertDate = (date) => {
    const d = new Date(date);
    const formattedDate = d.toDateString();
    return formattedDate.substring(3);
  };

  return (
    <ClientContext.Provider
      value={{
        signOut,
        getPositionData,
        convertDate,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientContextProvider;
