import React, { useEffect, useContext, useState } from 'react';
import PositionCard from './helpers/PositionCard';
import { Helmet } from 'react-helmet-async';
import { ClientContext } from '../../utils/ClientContext';
import { Row, Col, Container, Tab, Tabs } from 'react-bootstrap';

export default function User() {
  const { getPositionData } = useContext(ClientContext);

  const [positions, setPositions] = useState([]);

  useEffect(() => {
    getPositionData(['Blaine', 'Stillwater'], setPositions);
  }, []);

  return (
    <main>
      <Helmet>
        <title>Careers | Atlas Pet</title>
      </Helmet>

      <Container>
        <Row>
          <Col className='p-3'>
            <h1 className='theme-text1 m-1'>Available Positions</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey='All' id='position-tabs' className='mb-3'>
          <Tab eventKey='All' title='All locations'>
            {positions.map((position) => (
              <PositionCard position={position} key={position.id}/>
            ))}
          </Tab>
          <Tab eventKey='Blaine' title='Blaine, MN'>
            {positions?.filter(position => position.data.location === 'Blaine').map((position) => (
              <PositionCard position={position} key={position.id}/>
            ))}
          </Tab>
          <Tab eventKey='Stillwater' title='Stillwater, MN'>
          {positions?.filter(position => position.data.location === 'Stillwater').map((position) => (
              <PositionCard position={position} key={position.id}/>
            ))}
          </Tab>
        </Tabs>
      </Container>
    </main>
  );
}
