import React, { useContext } from 'react';
import { Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { AuthContext } from '../../../utils/AuthContext';
import TrainingModal from './helpers/TrainingModal';

export default function Training() {
    const { incompleteCount, showTrainings, setShowTrainings, trainings, employeeData } = useContext(AuthContext);

    return (
        <>
            {!showTrainings && <Row className='w-100'>
                <Col md={7} className='ml-auto mr-auto'>
                    <Jumbotron>
                        <h1>{`${incompleteCount < 0 ? 0 : incompleteCount} Incomplete Trainings`}</h1>
                        <p>
                            {incompleteCount < 1 ? 'Congratulations! You have no trainings right now.' : `Please complete your ${incompleteCount} outstanding training(s).`}
                        </p>
                        <p>
                            <Button variant='primary' className='button-standard' onClick={() => { setShowTrainings(true) }}>View Courses</Button>
                        </p>
                    </Jumbotron>
                </Col>
            </Row>}
            {showTrainings &&
                <Row className='p-2 pl-3 border-bottom'>
                    <Col sm={4} className='d-flex align-items-center'>Course Title</Col>
                    <Col sm={4} className='d-flex align-items-center'>Category</Col>
                    <Col sm={2} className='d-flex align-items-center'>Score</Col>
                    <Col sm={2} className='d-flex align-items-center justify-content-end'></Col>
                </Row>
            }
            {showTrainings && trainings && trainings.map((training, idx) => (
                <Row key={idx} className='p-2 pl-3 border-bottom'>
                    <Col sm={4} className='d-flex align-items-center'>{training.data.title}</Col>
                    <Col sm={4} className='d-flex align-items-center'>{training.data.category}</Col>
                    <Col sm={2} className='d-flex align-items-center'>{employeeData?.completedCourses && employeeData?.completedCourses[training.id]?.scorePercentage}</Col>
                    <Col sm={2} className='d-flex justify-content-end'><TrainingModal variant='dark' training={training} /></Col>
                </Row>
            ))}
        </>
    )
}
