import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import firebase from 'firebase';
import { deleteFile } from '../../../../utils/firebase';
import { AuthContext } from '../../../../utils/AuthContext';

export function AppStatusModal({ appId, setFullApp, fullApp }) {
  const db = firebase.firestore();
  const { employeeData } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [actionData, setActionData] = useState({
    appStatus: null,
    actionBy: `${employeeData.fName} ${employeeData.lName}`,
    actionDate: firebase.firestore.Timestamp.now(),
    interviewDate: null,
    notes: null,
  });

  const handleClose = () => {
    setConfirmation(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const deleteApplication = async (appId) => {
    db.collection('applications')
      .doc(appId)
      .update({
        status: 'deleted',
      })
      .then(async () => {
        await deleteFile(fullApp.data);
        handleClose();
        setFullApp(null);
      })
      .catch(function (error) {
        console.error('Error deleting document: ', error);
      });
  };

  const markApplicationStatusChange = (appId, statusObj) => {
    if (!statusObj?.actionBy || !statusObj?.appStatus) return;
    if (statusObj?.appStatus === 'scheduled' && !statusObj?.interviewDate) return;

    db.collection('applications')
      .doc(appId)
      .set(
        {
          actions: firebase.firestore.FieldValue.arrayUnion(statusObj),
          status: statusObj.appStatus,
        },
        { merge: true }
      )
      .then(() => {
        handleClose();
      })
      .catch(function (error) {
        console.error('Error marking application status: ', error);
      });
  };

  return (
    <>
      <Button variant='primary' className='print-hide' onClick={handleShow}>
        <img src='/APPIcons/pencil-fill.svg' alt='Edit Status' />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mark Application Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmitCapture={(e) => e.preventDefault()}>
            <Form.Group>
              <Form.Label className='m-0 text-theme'>
                Status <span className='text-danger'>*</span>
              </Form.Label>
              <Form.Control as='select' onChange={(e) => setActionData({ ...actionData, appStatus: e.target.value })}>
                <option value=''>---</option>
                <option value='reviewed'>Reviewed</option>
                <option value='scheduled'>Scheduled</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className='m-0 text-theme'>
                Marked by <span className='text-danger'>*</span>
              </Form.Label>
              <Form.Control as='input' disabled value={actionData.actionBy} />
              <Form.Label className='m-0 text-theme'>
                Not your user?{' '}
                <Button variant='link' onClick={() => firebase.auth().signOut()} className='p-0'>
                  Logout here.
                </Button>
              </Form.Label>
            </Form.Group>
            {actionData?.appStatus === 'scheduled' && (
              <Form.Group>
                <Form.Label className='m-0 text-theme'>
                  Interview date <span className='text-danger'>*</span>
                </Form.Label>
                <Form.Control
                  as='input'
                  type='datetime-local'
                  onChange={(e) => {
                    setActionData({ ...actionData, interviewDate: e.target.value });
                  }}
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label className='m-0 text-theme'>Notes</Form.Label>
              <Form.Control
                as='textArea'
                onChange={(e) => {
                  setActionData({ ...actionData, notes: e.target.value });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          {confirmation ? (
            <Button variant='danger' onClick={() => deleteApplication(appId)}>
              Confirm delete
            </Button>
          ) : (
            <Button variant='warning' onClick={() => setConfirmation(true)}>
              Delete application
            </Button>
          )}
          <Button variant='primary' onClick={() => markApplicationStatusChange(appId, actionData)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function ActionSection({ actions }) {
  return (
    <ul className='list-group mb-3 flex-column-reverse'>
      {actions?.map((action, idx) => (
        <li className='list-group-item print-hide p-2' key={idx}>
          <Col xs={12} className='text-info'>
            <strong>{`${action?.actionDate.toDate().toLocaleDateString()}`}:</strong> Marked <u>{action?.appStatus}</u>{' '}
            by {action?.actionBy}
          </Col>
          {action?.interviewDate && (
            <Col xs={12}>
              <strong>
                Interview scheduled: {new Date(action.interviewDate.substring(0, 10)).toLocaleDateString()} at{' '}
                {action.interviewDate.substring(11)}
              </strong>
            </Col>
          )}
          {action?.notes && <Col xs={12}>Notes: {action.notes}</Col>}
        </li>
      ))}
    </ul>
  );
}
