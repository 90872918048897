import React, { useRef } from 'react';
import { Form, Col } from 'react-bootstrap';

export default function FormTextArea({ question, idx, handleFieldChange, defaultValue, disabled }) {
  const ref = useRef();

  return (
    <Col xs={12}>
      <Form.Label className='text-dark d-inline-flex w-50'>{question}</Form.Label>
      <Form.Control
        as='textarea'
        defaultValue={defaultValue}
        ref={ref}
        onChange={() => {
          handleFieldChange(idx, question, ref.current.value);
        }}
        disabled={disabled}
      />
    </Col>
  );
}
