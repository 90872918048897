import React, { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export default function QuizToggleRadio({ choices, setQuestionResponse, id }) {
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('');

  const onChangeFunction = (e) => {
    setRadioValue(e.currentTarget.value);
    setQuestionResponse(id, e.currentTarget.value);
  };

  return (
    <>
      <ButtonGroup vertical className='mb-2 w-100'>
        {choices.map((choice, idx) => (
          <ToggleButton
            className='mb-2 rounded hide-radio'
            size='lg'
            key={idx}
            id={`radio-${idx}`}
            type='radio'
            variant={radioValue === choice ? 'success' : 'secondary'}
            name='radio'
            value={choice}
            checked={radioValue === choice}
            onChange={onChangeFunction}
          >
            {choice}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
}
