import React, { useEffect, useState } from 'react';
import { app } from './firebase';
import firebase from 'firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  var db = firebase.firestore(app);

  const [currentUser, setCurrentUser] = useState(null);
  const [signup, setSignup] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [incompleteCount, setIncompleteCount] = useState(0);
  const [showTrainings, setShowTrainings] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [adminData, setAdminData] = useState();

  const getEmployee = async () => {
    var employeeRef = db.collection('employees').doc(currentUser.uid);

    await employeeRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setEmployeeData(doc.data());
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  };

  const getAllEmployees = () => {
    db.collection('employees')
      .orderBy('fName')
      .get()
      .then((querySnapshot) => {
        let employeeArray = [];
        querySnapshot.forEach((doc) => {
          employeeArray.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setAllEmployees(employeeArray);
      })
      .catch((error) => {
        console.error('Error getting documents:', error);
      });
  };

  const updateEmployee = async (employeeUid, employeeData) => {
    return await db.collection('employees')
      .doc(employeeUid)
      .set(
        employeeData,
        { merge: true }
      )
      .then(() => {})
      .catch((error) => {
        console.error('Error updating location:', error);
      });
  };

  const deleteEmployee = (employeeId) => {
    var employeeRef = db.collection('employees').doc(employeeId);

    employeeRef
      .delete()
      .then(() => {
        getAllEmployees();
      })
      .catch((error) => {
        console.error('Error deleting employee: ', error);
      });
  };

  const setCourseCompletion = (courseId, scorePercentage) => {
    var employeeRef = db.collection('employees').doc(currentUser.uid);

    employeeRef
      .set(
        {
          completedCourses: {
            [courseId]: {
              scorePercentage: scorePercentage,
              completionDate: firebase.firestore.FieldValue.serverTimestamp(),
            },
          },
        },
        { merge: true }
      )
      .then(() => {})
      .catch((error) => {
        console.error('Error updating course completion:', error);
      });
  };

  const toggleForm = () => {
    if (signup === true) {
      setSignup(false);
    } else {
      setSignup(true);
    }
  };

  const getTrainings = () => {
    db.collection('trainings')
      .orderBy('title')
      .get()
      .then((querySnapshot) => {
        let trainings = [];
        querySnapshot.forEach((doc) => {
          let map = {
            id: doc.id,
            data: doc.data(),
          };
          trainings.push(map);
          // doc.data() is never undefined for query doc snapshots
        });
        setTrainings(trainings);
      });
  };

  const addTraining = (title, category, contentType, contentUrl, questions) => {
    db.collection('trainings')
      .add({
        title,
        category,
        contentType,
        contentUrl,
        questions:
          questions.length > 0
            ? questions
            : [
                {
                  question: ' ',
                  answer: 'Mark as complete',
                  choices: ['Mark as complete'],
                },
              ],
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  };

  const updateTraining = (id, title, category, contentType, contentUrl, questions) => {
    db.collection('trainings')
      .doc(id)
      .set(
        {
          title,
          category,
          contentType,
          contentUrl,
          questions:
            questions.length > 0
              ? questions
              : [
                  {
                    question: ' ',
                    answer: 'Mark as complete',
                    choices: ['Mark as complete'],
                  },
                ],
        },
        { merge: true }
      )
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  };

  const deleteTraining = (id) => {
    db.collection('trainings')
      .doc(id)
      .delete()
      .then(() => {
        getTrainings();
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  };

  const calculateCompletionCount = (trainings, completedCourses) => {
    if (!completedCourses) {
      return trainings.length;
    }
    if (completedCourses) {
      let keys = Object.keys(completedCourses);
      let courseCount = trainings.length;

      for (let i = 0; i < trainings.length; i++) {
        if (keys.includes(trainings[i].id)) {
          courseCount -= 1;
        }
      }

      return courseCount;
    }
  };

  const getAdminData = () => {
    db.collection('appData')
      .doc('adminData')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setAdminData(doc.data().courseCreator);
        }
      })
      .catch((error) => {
        console.error('Error getting admin data:', error);
      });
  };

  // Sets up to listen for changes to our firebase object
  useEffect(() => {
    var unsubscribe = app.auth().onAuthStateChanged(setCurrentUser);

    return function cleanup() {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    currentUser && getTrainings(employeeData);
  }, [employeeData]);

  useEffect(() => {
    setIncompleteCount(calculateCompletionCount(trainings, employeeData.completedCourses));
  }, [employeeData, trainings]);

  useEffect(() => {
    currentUser && getEmployee();
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        db,
        currentUser,
        signup,
        setSignup,
        updateEmployee,
        setCourseCompletion,
        deleteEmployee,
        toggleForm,
        getEmployee,
        getAllEmployees,
        employeeData,
        allEmployees,
        loading,
        incompleteCount,
        setIncompleteCount,
        showTrainings,
        setShowTrainings,
        trainings,
        setTrainings,
        getTrainings,
        addTraining,
        updateTraining,
        deleteTraining,
        adminData,
        getAdminData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
