import React, { useContext, useState } from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import { AuthContext } from '../../../../utils/AuthContext';

export default function DeleteSaleReport({ submitted = false, id }) {
  const { db } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteReport = () => {
    db.collection('salesData')
      .doc(id)
      .delete()
      .then(() => {
        handleClose();
      });
  };

  return (
    <>
      {submitted ? (
        <Badge>Submitted</Badge>
      ) : (
        <>
          <Button variant='danger' onClick={handleShow}>
            Delete
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Permanently remove report?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This action cannot be undone. Keep in mind, once a report is submitted it cannot be deleted.
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={deleteReport}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
