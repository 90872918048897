import React, { useState } from 'react';
import { Row, Col, Alert, Form, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { app } from '../../utils/firebase';

export default function ResetPassword() {
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
  });

  const navigate = useNavigate();

  const passwordReset = (e) => {
    e.preventDefault();
    setAlertMsg(null);
    app
      .auth()
      .sendPasswordResetEmail(formData.email)
      .then(function () {
        setResetEmailSent(true);
      })
      .catch(function (error) {
        setAlertMsg(error.message);
      });
  };

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Row>
      <Helmet>
        <title>Reset Password | Atlas Pet</title>
      </Helmet>
      <Col sm={8} lg={6} className='ml-auto mr-auto'>
        <Form className='border p-5 rounded shadow'>
          <h1 className='display-4'>Reset password</h1>
          <Form.Group className='mb-3' controlId='formEmail'>
            <Form.Label className='text-dark'>Email</Form.Label>
            <Form.Control type='email' onChange={(e) => handleFormChange('email', e.target.value)} />
          </Form.Group>

          <Row>
            <Col xs={8}>
              {resetEmailSent && !alertMsg && (
                <Alert className=' mt-4' variant='success'>
                  Sent! If your user exists you will receive an email in the next few minutes.
                  <Alert.Link onClick={() => navigate('/')}>
                    {' '}
                    <u>Return to login page</u>
                  </Alert.Link>
                </Alert>
              )}
              {alertMsg && (
                <Alert className=' mt-4' variant='danger'>
                  {alertMsg}
                </Alert>
              )}
            </Col>
            <Col className='text-right'>
              <Button className='mt-3' variant='primary' type='submit' onClick={(e) => passwordReset(e)}>
                Send reset email
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
