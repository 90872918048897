import React, { useContext, useState } from 'react';
import { Button, Modal, Form, Row, Alert } from 'react-bootstrap';
import RegisterCount from './RegisterSection';
import ReportDetails from './ReportDetails';
import firebase from 'firebase';
import { AuthContext } from '../../../../utils/AuthContext';
import TotalSummary from './reportFields/TotalSummary';

export default function SaleReportModal({ location, savedReport, id, submitted = false }) {
  const { employeeData, db } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [reportTotals, setReportTotals] = useState({});
  // Remember to include submitted in the actual document.add function

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (savedReport) {
      setReportTotals(savedReport);
    } else {
      setReportTotals({
        reportDate: new Date().toISOString().substring(0, 10),
        location,
        reg1: {},
        reg2: {},
        reg3: {},
      });
    }
    setShow(true);
  };

  const saveNew = (submit = false) => {
    db.collection('salesData').add({
      ...reportTotals,
      submitted: submit,
      submittedBy: `${employeeData.fName} ${employeeData.lName}`,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  const saveEdits = (submit = false) => {
    db.collection('salesData')
      .doc(id)
      .set(
        {
          ...reportTotals,
          submitted: submit,
          submittedBy: `${employeeData.fName} ${employeeData.lName}`,
          updated: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  };

  const handleSubmit = (submit) => {
    if (savedReport) {
      saveEdits(submit);
    } else {
      saveNew(submit);
    }
    handleClose();
  };

  return (
    <>
      <Button
        variant={submitted ? 'success' : savedReport ? 'secondary' : 'primary'}
        onClick={handleShow}
        className={savedReport ? 'w-75' : 'w-100'}
      >
        {submitted ? 'View' : savedReport ? 'Edit' : 'Add record'}
      </Button>

      <Modal size='xl' show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add sales total record ({location.cityState})</Modal.Title>
        </Modal.Header>
        <Modal.Body className='border-bottom'>
          <Alert variant='primary'>
            This report must be submitted by the manager on duty at the end of each open sale day. Only one report may
            be submitted each day. Once the submission is complete, it is sent to the main office and can no longer be
            edited
          </Alert>
          <Form>
            <Row>
              <ReportDetails
                reportTotals={reportTotals}
                setReportTotals={setReportTotals}
                disabled={savedReport?.submitted}
              />
            </Row>
            <Row>
              <RegisterCount
                register='reg1'
                reportTotals={reportTotals}
                setReportTotals={setReportTotals}
                disabled={savedReport?.submitted}
              />
              <RegisterCount
                register='reg2'
                reportTotals={reportTotals}
                setReportTotals={setReportTotals}
                disabled={savedReport?.submitted}
              />
              <RegisterCount
                register='reg3'
                reportTotals={reportTotals}
                setReportTotals={setReportTotals}
                disabled={savedReport?.submitted}
              />
            </Row>
          </Form>
        </Modal.Body>
        <TotalSummary reportTotals={reportTotals} setReportTotals={setReportTotals} />
        <Modal.Footer>
          <Form.Label className='align-self-center my-0 mx-3 text-secondary'>
            {submitted ? 'This form has been submitted' : 'Once a form is submitted'}, it can no longer be edited.
          </Form.Label>
          <Button disabled={submitted} variant='secondary' onClick={() => handleSubmit(false)}>
            Save
          </Button>
          <Button disabled={submitted} variant='primary' onClick={() => handleSubmit(true)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
